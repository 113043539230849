import React, { useEffect } from "react";
import { AppendScript } from "../../common/site/config";

const AllAgents = () => {
  useEffect(() => {
    if (typeof window !== "undefined") {
      AppendScript("https://www.allagents.co.uk/widgets/reviews.min.js", "allagents_review", () => {
        setTimeout(() => {
          if(window) {
            window.init_widget_v2(400,1000,7682,1000,"rating","4")
          }
        }, 1500)
      });
    }
  }, [])
  return (
    <div id="wd_id"></div>
  )
}
export default AllAgents

